// import React, { useState, useEffect } from 'react';
import React from 'react';
import './Header.css'; // Ensure this file exists and is correctly linked
import logo from '../static/assets/logo.png'; // Adjust path as necessary

const Header = () => {
  return (
    <header className="header">
      <div className="logo-container-header">
        <a href='/' className="logo-link">
          <img alt='Logo' src={logo} className="logo-img-header" />
        </a>
        {/* {!isMobileView && <span className="logo-text"><p>4Tekies LLC <sup>&trade;</sup></p></span>} */ }
        <span className="logo-text"><p>4Tekies LLC <sup>&trade;</sup></p></span>
      </div>
    </header>
  );
};

export default Header;
