import './App.css';
import Header from './components/header';
import Landing from './pages/landing';

function App() {
  return (
    <>
      <Header />
      <Landing />
    </>
   
  );
}

export default App;
